import React, { useState, useEffect, useRef } from "react";
import useScrollDetector from "scroll-detect-hook";
import Draggable from "react-draggable";

function StageElement(props) {
  const [isScrolling, direction, speed, position] = useScrollDetector();
  const [stageW, setStageW] = useState("auto");

  const stage = useRef(null);

  /*eslint-disable */
  useEffect(() => {
    setTimeout(function () {
      let totalWidthArray = [];
      let animateDiv = stage.current;
      if (animateDiv) {
        for (var i = 0; i < animateDiv.children.length; i++) {
          totalWidthArray.push(animateDiv.children[i].offsetWidth);
        }
        setStageW(Math.max(...totalWidthArray));
      }
    }, 1000);
  });
  /*eslint-enable */

  if (isScrolling) {
    const distance = window.scrollY;

    let stages = document.getElementsByClassName("stage-element-wrapper");

    for (var i = 0; i < stages.length; i++) {
      let hasParallax = stages[i].getAttribute("parallax");
      if (hasParallax) {
        stages[i].style.transform = `translateX(-${distance * hasParallax}px)`;
      } else {
        stages[i].style.transform = `translateX(-${distance}px)`;
      }
    }
  }

  let stageEl = (
    <div
      className={`stage-element-wrapper  ${
        props.className ? props.className : ""
      }`}
      parallax={props.parallax}
      ref={stage}
      style={{
        left: props.left,
        width: !props.plusWidth ? stageW : stageW + props.plusWidth,
      }}
    >
      {props.children}
    </div>
  );

  return stageEl;
}

export default StageElement;
