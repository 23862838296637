import React from "react";
import useScrollDetector from "scroll-detect-hook";

import background from "../../../assets/images/bg-2.jpg";
import background2 from "../../../assets/images/bg-4.png";

function Background(props) {
  let backgroundImage;
  if (props.type === "2") {
    backgroundImage = background2;
  } else {
    backgroundImage = background;
  }
  return (
    <img
      className="stage-element"
      alt=""
      src={backgroundImage}
      id="background"
    />
  );
}

export default Background;
