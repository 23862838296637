import React from "react";
import live from "../../../assets/images/live.png";

function Lives(props) {
  return (
    <div className="lives-wrapper">
      <img alt="" src={live} id="live" />
      <img alt="" src={live} id="tree" />
      <img alt="" src={live} id="tree" />
    </div>
  );
}

export default Lives;
