import React from "react";
import Year from "../Year/Year";
import StageElement from "../StageElement/StageElement";
import Epvr from "../Epvr/Epvr";

//UTILS
import { Distances } from "../../../helpers/distances";

//CLIENTS
import ClientWrapper from "./ClientWrapper";

function Clients() {
  const distancesYears = Distances().years;
  const distancesSections = Distances().sections;
  const distancesClients = Distances().clients;

  const ClientBlock = ({ year, client, left }) => {
    return (
      <>
        <StageElement left={distancesYears[year]}>
          <Year year={year} />
        </StageElement>
        <StageElement
          left={distancesClients[client]}
          className={client + "-wrapper client-wrapper"}
        >
          <ClientWrapper client={client} />
        </StageElement>
      </>
    );
  };

  return (
    <>
      {/*EPVR*/}
      <StageElement left={distancesYears["2003"]}>
        <Year year="2003" />
      </StageElement>
      <StageElement left={distancesSections.epvr} className="epvr-wrapper">
        <Epvr />
      </StageElement>

      {/*PEUGEOT*/}
      <ClientBlock
        year="2006"
        client="peugeot"
        left={distancesClients.peugeot}
      />

      {/*JELLYCODE*/}
      <ClientBlock
        year="2010"
        client="jellycode"
        left={distancesClients.jellycode}
      />

      {/*OCEANPHONE*/}
      <ClientBlock
        year="2014"
        client="oceanfone"
        left={distancesClients.oceanfone}
      />

      {/*CVWAREHOUSE*/}
      <ClientBlock
        year="2018"
        client="cvwarehouse"
        left={distancesClients.cvwarehouse}
      />

      {/*MARVIL56*/}
      <ClientBlock
        year="2019"
        client="marvil56"
        left={distancesClients.marvil56}
      />

      {/*MINDK*/}
      <ClientBlock year="2020" client="mindk" left={distancesClients.mindk} />
    </>
  );
}

export default Clients;
