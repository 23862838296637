import React from "react";
import StageElement from "../StageElement/StageElement";
import Bush from "./Bush";
import { Distances } from "../../../helpers/distances";

function Bushs(props) {
  const bushs = [];
  const distances = Distances().bushs;

  for (const [index, value] of distances.entries()) {
    bushs.push(
      <StageElement left={value}>
        <Bush index={index} />
      </StageElement>
    );
  }

  return <div className="bushs-wrapper">{bushs}</div>;
}

export default Bushs;
