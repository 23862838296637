import React from "react";
import RoleModal from "./RoleModal";
import SkillsModal from "./SkillsModal";
import ProjectsModal from "./ProjectsModal";

function Modal(props) {
  let modal;
  switch (props.type) {
    case "role":
      modal = <RoleModal client={props.client} />;
      break;
    case "skills":
      modal = <SkillsModal client={props.client} />;
      break;
    case "projects":
      modal = <ProjectsModal client={props.client} />;
      break;
  }
  return modal;
}

export default Modal;
