import React from "react";
import { Distances } from "../../../helpers/distances";
import StageElement from "../../../components/Stage/StageElement/StageElement";
import Chair from "./Chair";
import Truck from "./Truck";
import SayHi from "./SayHi";

function Contacts() {
  const distancesContacts = Distances().sections.contacts;

  return (
    <div className="contacts-section-wrapper">
      <StageElement left={distancesContacts.chair}>
        <Chair />
      </StageElement>
      <StageElement left={distancesContacts.truck}>
        <Truck />
      </StageElement>

      <StageElement left={distancesContacts.sayhi}>
        <SayHi />
      </StageElement>
    </div>
  );
}

export default Contacts;
