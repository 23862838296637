import React from "react";

import StageElement from "../StageElement/StageElement";
import Tree from "./Tree";
import { Distances } from "../../../helpers/distances";

function Trees(props) {
  const trees = [];
  const distances = Distances().trees;

  for (const [index, value] of distances.entries()) {
    trees.push(
      <StageElement left={value}>
        <Tree index={index} />
      </StageElement>
    );
  }

  return <div className="trees-wrapper">{trees}</div>;
}

export default Trees;
