import React, { useState, useEffect } from "react";
import Sound from "react-sound";
import useScrollDetector from "scroll-detect-hook";
import { lang } from "../../../helpers/lang";
import { useReactiveVar } from "@apollo/client";

import { Distances } from "../../../helpers/distances";
import { playerActions } from "../../../apollo/store";

import steps from "../../../assets/sounds/steps4.wav";
import meRunningRight from "../../../assets/images/me-r.gif";
import meRunningLeft from "../../../assets/images/me-l.gif";
import meNormal from "../../../assets/images/me-normal.gif";
import speech from "../../../assets/images/speech.png";

export const Speech = (props) => {
  return (
    <div className="speech ">
      <img src={speech} alt="" />
      <div className="inside">{props.text}</div>
    </div>
  );
};

function Me() {
  const [isScrolling, direction, speed, position] = useScrollDetector();
  const [speech, setSpeech] = useState();
  const speechDistances = Distances().me_speech;
  const [AudioPosition, setAudioPosition] = useState(0);
  const { stepsVolume, muted } = useReactiveVar(playerActions);

  const detectDistance = (inDistance, outDistance, text) => {
    if (position > inDistance && position < outDistance) {
      console.log("###");
      setSpeech(text);
    }
    if (position > outDistance) {
      setSpeech();
    }
  };

  /*eslint-disable */
  useEffect(() => {
    detectDistance(0, 1, "Hi there!");

    if (direction !== "up") {
      detectDistance(
        speechDistances.start.in,
        speechDistances.start.out,
        "Turn the music on :)"
      );
      detectDistance(
        speechDistances.go.in,
        speechDistances.go.out,
        "Let's go!"
      );
      detectDistance(
        speechDistances.cant.in,
        speechDistances.cant.out,
        "You will see!"
      );
      detectDistance(
        speechDistances.oceanfone.in,
        speechDistances.oceanfone.out,
        "I learned a lot here."
      );
    }
  });
  /*eslint-enable */

  const repeat = () => {
    setAudioPosition(0);
  };

  const onPlaying = (ev) => {
    setAudioPosition(ev.position);
  };

  return (
    <div className="me-character-wrapper">
      <Sound
        url={steps}
        /*
        playStatus={
          direction !== "stopped" && !muted
            ? Sound.status.PLAYING
            : Sound.status.STOPPED
        }
        */
        playStatus={Sound.status.STOPPED}
        volume={stepsVolume}
        loop={true}
        //playbackRate={3}
      />

      {speech && <Speech text={speech} />}
      <img
        alt=""
        src={
          direction !== "stopped"
            ? direction === "down"
              ? meRunningRight //right
              : meRunningLeft //left
            : meNormal
        }
      />
    </div>
  );
}

export default Me;
