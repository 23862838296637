export const vhTOpx = (value) => {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;

  var result = (y * value) / 100;
  return result;
};

export const findPosLeft = (ref) => {
  var node = ref.current;
  var curtop = 0;
  var curtopscroll = 0;
  if (node.offsetParent) {
    do {
      curtop += node.offsetLeft;
      curtopscroll += node.offsetParent ? node.offsetParent.scrollLeft : 0;
    } while ((node = node.offsetParent));

    return curtop - curtopscroll;
  }
};

export const findPosTop = (ref) => {
  var node = ref.current;
  var curtop = 0;
  var curtopscroll = 0;
  if (node.offsetParent) {
    do {
      curtop += node.offsetTop;
      curtopscroll += node.offsetParent ? node.offsetParent.scrollTop : 0;
    } while ((node = node.offsetParent));

    return curtop - curtopscroll;
  }
};

export const updatedObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};
