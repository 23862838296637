import React from "react";
import Skill from "./Skill";
import { Distances } from "../../../helpers/distances";
import StageElement from "../StageElement/StageElement";

function Skills() {
  return (
    <StageElement
      left={Distances().sections.skills}
      className="skills-bar-wrapper"
    >
      <Skill skill="php" />
      <Skill skill="mysql" />
      <Skill skill="javascript" />
      <Skill skill="react" />
      <Skill skill="sass" />
      <Skill skill="ubuntu" />
      <Skill skill="wordpress" />
      <Skill skill="illustrator" />
      <Skill skill="photoshop" />
    </StageElement>
  );
}

export default Skills;
