import React, { useState, useEffect, useRef } from "react";
import useScrollDetector from "scroll-detect-hook";

import { vhTOpx, findPosLeft } from "../../../helpers/utils";

let inCallbackExecuted = false;
let outCallbackExecuted = false;

function Animate(props) {
  const [isScrolling, direction] = useScrollDetector();

  const [animateW, setAnimateW] = useState("auto");
  const [animateH, setAnimateH] = useState("auto");
  const ref = useRef(null);

  /*eslint-disable */
  useEffect(() => {
    setTimeout(function () {
      //SET ANIMATE DIV DIMENSION LIKE PARENT
      let totalWidthArray = [];
      let totalHeightArray = [];
      let animateDiv = ref.current;

      if (animateDiv) {
        for (var i = 0; i < animateDiv.children.length; i++) {
          totalWidthArray.push(animateDiv.children[i].offsetWidth);
          totalHeightArray.push(animateDiv.children[i].offsetHeight);
        }
        setAnimateW(Math.max(...totalWidthArray));
        setAnimateH(Math.max(...totalHeightArray));
      }
    }, 3000);
  }, []);

  /*eslint-enable */

  if (isScrolling) {
    const distance = window.scrollY;
    let animateDistance = findPosLeft(ref) - distance;
    let elementStartTrigger = Math.round(
      vhTOpx("100") / 2 + vhTOpx(props.offset)
    );
    let elementEndTrigger = Math.round(-Math.abs(animateW / 2 - animateW / 3));

    let log_distances = () => {
      console.log("Direction: " + direction);
      console.log("Distance: " + distance);
      console.log("animateDistance: " + animateDistance);
      console.log("elementStartTrigger: " + elementStartTrigger);
      console.log("elementEndTrigger: " + elementEndTrigger);
    };

    const initEnter = () => {
      if (props.inAnimation) {
        ref.current.classList.remove(props.outAnimation);
        ref.current.classList.add(props.inAnimation);
      }
      if (props.inCallback && !inCallbackExecuted) {
        inCallbackExecuted = true;
        outCallbackExecuted = false;
        props.inCallback();
        //log_distances();
      }
    };

    const initLeave = () => {
      if (props.inAnimation) {
        ref.current.classList.add(props.outAnimation);
        ref.current.classList.remove(props.inAnimation);
      }
      if (props.outCallback && !outCallbackExecuted) {
        inCallbackExecuted = false;
        outCallbackExecuted = true;
        props.outCallback();
        //log_distances();
      }
    };

    if (direction === "down") {
      //ENTER ELEMENT
      if (props.id === "peugeot-speech-animation") {
        //console.log("Distance: " + distance);
        //console.log("animateDistance: " + animateDistance);
        //console.log("elementStartTrigger: " + elementStartTrigger);
        //console.log("elementEndTrigger: " + elementEndTrigger);
      }
      if (
        animateDistance < elementStartTrigger && // 551 < 880
        animateDistance > elementEndTrigger // 551 > -124
      ) {
        initEnter();
      }
      //LEAVE ELEMENT
      if (animateDistance < 0 && animateDistance < elementEndTrigger) {
        // -198 < -124
        initLeave();
      }
    }

    if (direction === "up") {
      //ENTER ELEMENT
      if (
        animateDistance > elementEndTrigger && // -95 > -124
        animateDistance < elementStartTrigger // -95 < 880
      ) {
        initEnter();
      }
      //LEAVE ELEMENT
      if (animateDistance > elementStartTrigger) {
        initLeave();
      }
    }
  }

  return (
    <div
      id={props.id}
      className={`animate__animated animated ${props.className}`}
      ref={ref}
      style={{
        width: animateW,
        height: animateH,
      }}
    >
      {props.children}
    </div>
  );
}

export default Animate;
