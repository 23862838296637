import React from "react";
import Drone from "./Drone";
import { lang } from "../../../helpers/lang";

function DronesWrapper({ client, className, left }) {
  return (
    <div
      className={`drones-wrapper ${className}`}
      style={{ left: left + "px" }}
    >
      <div className="drones drone-role">
        <Drone type="role" label={lang.drones.role} client={client} />
      </div>
      {/*
      <div className="drones drone-projects">
        <Drone type="projects" label="Projects" client={props.client} />
      </div>
      */}
      <div className="drones drone-skills">
        <Drone type="skills" label={lang.drones.skills} client={client} />
      </div>
    </div>
  );
}

export default DronesWrapper;
