import React from "react";
import useSound from "use-sound";
import { playerActions } from "../../../apollo/store";
import { useReactiveVar } from "@apollo/client";

import { lang } from "../../../helpers/lang";
import glass from "../../../assets/images/glass.png";
import linkedin from "../../../assets/images/Linkedin.png";
import mail from "../../../assets/images/mail.png";
import portfolio from "../../../assets/images/portfolio.png";

import fx from "../../../assets/sounds/fx4.wav";
import fxOut from "../../../assets/sounds/fx5.wav";

const SayHi = () => {
  const { btnVolume, muted } = useReactiveVar(playerActions);

  /* FX */
  const [playBtn] = useSound(fx, {
    volume: btnVolume,
  });
  const [playBtnOut] = useSound(fxOut, {
    volume: btnVolume,
  });

  return (
    <div className="sayhi-wrapper">
      <h1>
        {lang.sayhi.title}{" "}
        <img className="glass-img" alt="" src={glass} id="glass" />
      </h1>
      <p>{lang.sayhi.text}</p>
      <div className="icon-list">
        <a
          href="https://linkedin.com/in/bsantoscv/"
          target="_blank"
          onMouseEnter={() => {
            if (!muted) {
              playBtn();
            }
          }}
          onClick={() => {
            if (!muted) {
              playBtnOut();
            }
          }}
        >
          <img className="linkedin-img" alt="" src={linkedin} id="linkedin" />
          <div className="icon-name">LinkedIn</div>
        </a>
        <a
          href="mailto:bsantos.varve@gmail.com"
          target="_blank"
          onMouseEnter={() => {
            if (!muted) {
              playBtn();
            }
          }}
          onClick={() => {
            if (!muted) {
              playBtnOut();
            }
          }}
        >
          <img className="mail-img" alt="" src={mail} id="mail" />
          <div className="icon-name">Email</div>
        </a>
        <a
          href="https://mydeveloperjourney.pt/CV/CV-Bruno.pdf"
          target="_blank"
          onMouseEnter={() => {
            if (!muted) {
              playBtn();
            }
          }}
          onClick={() => {
            if (!muted) {
              playBtnOut();
            }
          }}
        >
          <img
            className="portfolio-img"
            alt=""
            src={portfolio}
            id="portfolio"
          />
          <div className="icon-name">Portfolio</div>
        </a>
      </div>
    </div>
  );
};

export default SayHi;
