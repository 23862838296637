import React from "react";
//import { useSelector, useDispatch } from "react-redux";

import close from "../../../assets/images/close.png";
import meWin from "../../../assets/images/me-win.gif";
import { skillSet } from "../../../helpers/skillIcons";
//import allActions from "../../../actions";

function SkillsModal(props) {
  let skills;
  //const dispatch = useDispatch();

  switch (props.client) {
    case "peugeot": {
      skills = [
        skillSet.photoshop,
        skillSet.illustrator,
        skillSet.php,
        skillSet.javascript,
        skillSet.mysql,
      ];
      break;
    }
    case "jellycode": {
      skills = [
        skillSet.photoshop,
        skillSet.illustrator,
        skillSet.php,
        skillSet.javascript,
        skillSet.mysql,
        skillSet.wordpress,
      ];
      break;
    }
    case "oceanfone": {
      skills = [
        skillSet.photoshop,
        skillSet.illustrator,
        skillSet.php,
        skillSet.javascript,
        skillSet.mysql,
        skillSet.wordpress,
        skillSet.sass,
        skillSet.ubuntu,
      ];
      break;
    }
    case "cvwarehouse": {
      skills = [
        skillSet.photoshop,
        skillSet.illustrator,
        skillSet.php,
        skillSet.javascript,
        skillSet.sass,
      ];
      break;
    }
    case "marvil56": {
      skills = [
        skillSet.php,
        skillSet.javascript,
        skillSet.mysql,
        skillSet.wordpress,
        skillSet.react,
        skillSet.sass,
        skillSet.ubuntu,
      ];
      break;
    }
    case "mindk": {
      skills = [
        skillSet.php,
        skillSet.javascript,
        skillSet.mysql,
        skillSet.wordpress,
        skillSet.react,
        skillSet.sass,
        skillSet.ubuntu,
      ];
      break;
    }
  }

  const closeModal = (e) => {
    e.preventDefault();
    //dispatch(allActions.modalActions.closeModal());
  };

  return (
    <div className="modal-wrapper-inside ">
      <div className="modal skils-modal-wrapper">
        <div className="inside">
          <div className="me-win slide-in-left">
            <img src={meWin} />
          </div>
          <div className="skills">
            {skills.map((item) => {
              return (
                <div className="skill-wrapper scale-in-center">
                  <div className="icon">
                    <img src={item.icon} />
                  </div>
                  <div className="name">{item.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkillsModal;
