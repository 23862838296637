import React, { useEffect } from "react";
import anime from "animejs/lib/anime.es.js";

function Loading() {
  useEffect(() => {
    var textWrapper = document.querySelector(".ml6 .letters");
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /\S/g,
      "<span class='letter'>$&</span>"
    );

    anime.timeline({ loop: true }).add({
      targets: ".ml6 .letter",
      translateY: ["1.5em", 0],
      translateZ: 0,
      duration: 750,
      delay: (el, i) => 50 * i,
    });
    /*.add({
        targets: ".ml6",
        opacity: 0,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000
      });*/
  }, []);

  return (
    <div className="loading-wrapper">
      <div className="mask"></div>
      <h1 class="ml6">
        <span class="text-wrapper">
          <span class="letters">LOADING</span>
          <div className="wait">Please wait</div>
        </span>
      </h1>

      <div class="sk-chase">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
    </div>
  );
}

export default Loading;
