import React from "react";
import InfiniteCarousel from "react-leaf-carousel";

function ProjectsModal(props) {
  let images;
  switch (props.client) {
    case "peugeot": {
      images = [
        { url: "https://placeimg.com/640/480/any" },
        { url: "https://placeimg.com/640/482/any" },
        { url: "https://placeimg.com/640/482/any" }
      ];
      break;
    }
  }

  return (
    <div className="modal projects-modal-wrapper pixel">
      <div className="inside">
        <InfiniteCarousel
          breakpoints={[
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]}
          dots={true}
          showSides={true}
          sidesOpacity={0.5}
          sideSize={0.1}
          slidesToScroll={4}
          slidesToShow={4}
          scrollOnDevice={true}
        >
          <div>
            <img
              alt=""
              src="https://placeholdit.imgix.net/~text?txtsize=20&bg=616161&txtclr=ffffff&txt=215%C3%97215&w=215&h=215"
            />
          </div>
          <div>
            <img
              alt=""
              src="https://placeholdit.imgix.net/~text?txtsize=20&bg=ed7ebe&txtclr=ffffff&txt=215%C3%97215&w=215&h=215"
            />
          </div>
        </InfiniteCarousel>
      </div>
    </div>
  );
}

export default ProjectsModal;
