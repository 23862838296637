import React, { useState, useEffect, useRef } from "react";
import cant from "../../../assets/images/cant.png";
import Animate from "../../UI/Animate/Animate";
import { useReactiveVar } from "@apollo/client";
import { lang } from "../../../helpers/lang";
import { cantActions, playerActions } from "../../../apollo/store";
import { set } from "immutable-modify";

//UTILS
var classNames = require("classnames");

function Cant(props) {
  const { cantOn } = useReactiveVar(cantActions);
  const {
    backgroundgIsPlaying,
    cantIsPlaying,
    backgroundVolume,
    cantVolume,
    muted,
  } = useReactiveVar(playerActions);

  var imgClass = classNames({
    "stage-element wrapper": true,
    on: cantOn,
  });

  const cantOnCallback = () => {
    document.body.classList.add("cant-on");
    cantActions(set(cantActions(), "cantOn", true));
    if (!muted) {
      playerActions(set(playerActions(), "backgroundIsPlaying", false));
      playerActions(set(playerActions(), "cantIsPlaying", true));
    }
  };

  const cantOffCallback = () => {
    document.body.classList.remove("cant-on");
    cantActions(set(cantActions(), "cantOn", false));
    if (!muted) {
      playerActions(set(playerActions(), "backgroundIsPlaying", true));
      playerActions(set(playerActions(), "cantIsPlaying", false));
    }
  };

  return (
    <>
      <Animate
        id="cant-speech-animation"
        offset="80"
        inAnimation="fadeInDown"
        outAnimation="fadeOutUp"
        inCallback={() => cantOnCallback()}
        outCallback={() => cantOffCallback()}
      >
        <div className="speech speech-one">{lang.cant}</div>
      </Animate>

      <img className={imgClass} alt="" src={cant} />
    </>
  );
}

export default Cant;
