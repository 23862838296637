import React from "react";
import DronesWrapper from "./DronesWrapper";
import StageElement from "../../Stage/StageElement/StageElement";
import { Distances } from "../../../helpers/distances";

const AllDrones = () => {
  const distancesDrones = Distances().drones;
  const clients = [
    "peugeot",
    "jellycode",
    "oceanfone",
    "cvwarehouse",
    "marvil56",
    "mindk",
  ];

  const drones = clients.map((client) => (
    <StageElement left={distancesDrones[client]}>
      <DronesWrapper client={client} />
    </StageElement>
  ));

  return drones;
};

export default AllDrones;
