import React from "react";
import Modal from "./Modal";
import { useReactiveVar } from "@apollo/client";
import useSound from "use-sound";

import fx from "../../../assets/sounds/fx4.wav";
import fxOut from "../../../assets/sounds/fx5.wav";

//UTILS
import { modalActions, playerActions } from "../../../apollo/store";

import close from "../../../assets/images/close.png";

function Modals(props) {
  const { type, client, open } = useReactiveVar(modalActions);
  const { btnVolume, muted } = useReactiveVar(playerActions);

  const closeModal = (e) => {
    e.preventDefault();
    document.body.classList.remove("no-scroll");
    modalActions({
      client: null,
      type: null,
      open: false,
    });
  };

  /* FX */
  const [playBtn] = useSound(fx, {
    volume: btnVolume,
  });
  const [playBtnOut] = useSound(fxOut, {
    volume: btnVolume,
  });

  return (
    <>
      {open && (
        <div className="modal-wrapper" aria-hidden="true">
          <div className="mask"></div>
          <a
            href="#"
            class="close-btn animate__backInRight animate__animated"
            onClick={(e) => {
              if (!muted) {
                playBtnOut();
              }
              closeModal(e);
            }}
            onMouseEnter={() => {
              if (!muted) {
                playBtn();
              }
            }}
          >
            <img src={close} />
          </a>
          <Modal client={client} type={type} />
        </div>
      )}
    </>
  );
}

export default Modals;
