import React from "react";
import { Distances } from "../../../helpers/distances";
import labelAbout from "../../../assets/images/labels/about.png";
import labelExperience from "../../../assets/images/labels/experience.png";
import labelSkills from "../../../assets/images/labels/skills.png";
import labelContacts from "../../../assets/images/labels/contacts.png";
import meta from "../../../assets/images/labels/meta.png";
import StageElement from "../../../components/Stage/StageElement/StageElement";

function Labels({ type }) {
  const distancesLabels = Distances().labels;
  let labelImg = {
    about: labelAbout,
    experience: labelExperience,
    skills: labelSkills,
    contacts: labelContacts,
  };
  return (
    <>
      <StageElement
        left={distancesLabels[type]}
        className={type + "-wrapper label-wrapper"}
      >
        <img src={labelImg[type]} className="label-img" />
        <img src={meta} className="label-meta" />
      </StageElement>
    </>
  );
}

export default Labels;
