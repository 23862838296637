import React, { useState, useEffect, useRef } from "react";
import car from "../../../assets/images/car.gif";
import car2 from "../../../assets/images/car2.gif";

function Car(props) {
  return (
    <img
      className="stage-element"
      alt=""
      src={props.car === 1 ? car : car2}
      style={{ transform: `translateX(${props.vw}px)` }}
    />
  );
}

export default Car;
