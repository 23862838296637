import React, { useState, useEffect, useRef } from "react";
import { set } from "immutable-modify";

//UTILS
import { appActions, playerActions } from "../../../apollo/store";
import Sound from "react-sound";

import { useReactiveVar } from "@apollo/client";

import backgroundMusic from "../../../assets/sounds/background6.ogg";
import cantMusic from "../../../assets/sounds/cant.ogg";

import sound from "../../../assets/images/sound.png";
import no_sound from "../../../assets/images/no_sound.png";

function Player() {
  const { loadingTimeout } = useReactiveVar(appActions);
  const {
    backgroundIsPlaying,
    cantIsPlaying,
    backgroundVolume,
    cantVolume,
    muted,
  } = useReactiveVar(playerActions);

  const [backgroundAudioPosition, setBackgroundAudioPosition] = useState(0);
  const [cantAudioPosition, setCantAudioPosition] = useState(1500);

  const startPlay = (type) => {
    switch (type) {
      case "background":
        playerActions(set(playerActions(), "backgroundIsPlaying", true));
        break;
      case "cant":
        playerActions(set(playerActions(), "cantIsPlaying", true));
        break;
    }
  };

  const stopPlay = (type) => {
    switch (type) {
      case "background":
        playerActions(set(playerActions(), "backgroundIsPlaying", false));
        break;
      case "cant":
        playerActions(set(playerActions(), "cantIsPlaying", false));
        break;
    }
  };

  const repeat = (type) => {
    switch (type) {
      case "background":
        setBackgroundAudioPosition(0);
        break;
      case "cant":
        setCantAudioPosition(1500);
        break;
    }
  };

  const onPlaying = (ev, type) => {
    switch (type) {
      case "background":
        setBackgroundAudioPosition(ev.position);
        break;
      case "cant":
        setCantAudioPosition(ev.position);
        break;
    }
  };

  const checkPlayAction = () => {
    playerActions(set(playerActions(), "muted", !muted));

    if (backgroundIsPlaying && !cantIsPlaying) {
      stopPlay("background");
    } else if (!backgroundIsPlaying && cantIsPlaying) {
      stopPlay("cant");
    } else if (!backgroundIsPlaying && !cantIsPlaying) {
      startPlay("background");
    }
  };

  return (
    <div className="player-wrapper">
      <Sound
        url={cantMusic}
        playStatus={
          cantIsPlaying && !muted ? Sound.status.PLAYING : Sound.status.PAUSED
        }
        position={cantAudioPosition}
        onPlaying={(ev) => onPlaying(ev, "cant")}
        onFinishedPlaying={() => repeat("cant")}
        volume={cantVolume}
      />

      <Sound
        url={backgroundMusic}
        playStatus={
          backgroundIsPlaying && !muted
            ? Sound.status.PLAYING
            : Sound.status.PAUSED
        }
        position={backgroundAudioPosition}
        onPlaying={(ev) => onPlaying(ev, "background")}
        onFinishedPlaying={() => repeat("background")}
        volume={backgroundVolume}
      />
      <button onClick={() => checkPlayAction()}>
        {!muted ? (
          <img alt="" src={sound} id="sound" />
        ) : (
          <img alt="" src={no_sound} id="sound" />
        )}
      </button>
    </div>
  );
}

export default Player;
