import React from "react";
import useScrollDetector from "scroll-detect-hook";

import ground from "../../../assets/images/bg-1.png";

function Background() {
  const [isScrolling, direction, speed, position] = useScrollDetector();

  if (isScrolling) {
    const distance = window.scrollY;

    /*document.querySelector(
      "#ground"
    ).style.transform = `translateX(-${distance}px)`;*/
  }

  //return <img className="stage-element" alt="" src={ground} id="ground" />;
  return <div className="ground-wrapper"></div>;
}

export default Background;
