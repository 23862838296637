import React, { useState, useEffect, useRef } from "react";

import useScrollDetector from "scroll-detect-hook";

import year from "../../../assets/images/year.png";
import outdoor from "../../../assets/images/outdoor.gif";

function Year(props) {
  const [isScrolling, direction, speed, position] = useScrollDetector();

  return (
    <div className={`year-wrapper`}>
      <img alt="" src={outdoor} id="year" />
      <div className="year">{props.year}</div>
    </div>
  );
}

export default Year;
