import { useReactiveVar } from "@apollo/client";
import { appActions } from "../apollo/store";

const calculateDistance = (percentToGet, bgW) => {
  var percent = (percentToGet / 100) * bgW;
  return percent;
};

export const Distances = () => {
  const { bgW } = useReactiveVar(appActions);

  const sections = {
    epvr: 10,
    cant: 16,
    peugeot: 23,
    jellycode: 33,
    oceanfone: 43,
    cvwarehouse: 53,
    marvil56: 63,
    mindk: 73,
    skills: 85,
    contacts: 95.5,
  };

  return {
    sections: {
      epvr: calculateDistance(sections.epvr, bgW),
      cant: calculateDistance(sections.cant, bgW),
      skills: calculateDistance(sections.skills, bgW),
      contacts: {
        start: calculateDistance(sections.contacts, bgW),
        chair: calculateDistance(sections.contacts + 2.5, bgW),
        truck: calculateDistance(sections.contacts + 3.4, bgW),
        sayhi: calculateDistance(sections.contacts + 2.5, bgW),
      },
    },
    labels: {
      experience: calculateDistance(20.5, bgW),
      skills: calculateDistance(83, bgW),
      contacts: calculateDistance(94, bgW),
    },
    trees: [
      calculateDistance(0, bgW),
      calculateDistance(4, bgW),
      calculateDistance(9, bgW),
      calculateDistance(14, bgW),
      calculateDistance(19, bgW),
      calculateDistance(25, bgW),
      calculateDistance(32, bgW),
      calculateDistance(40.5, bgW),
      calculateDistance(48, bgW),
      calculateDistance(51, bgW),
      calculateDistance(61, bgW),
      calculateDistance(65.7, bgW),
      calculateDistance(81.5, bgW),
      calculateDistance(92, bgW),
      calculateDistance(97, bgW),
      calculateDistance(102.5, bgW),
    ],
    bushs: [
      calculateDistance(2, bgW),
      calculateDistance(5.3, bgW),
      calculateDistance(7, bgW),
      calculateDistance(18, bgW),
      calculateDistance(22.5, bgW),
      calculateDistance(29.5, bgW),
      calculateDistance(35.5, bgW),
      calculateDistance(42, bgW),
      calculateDistance(62, bgW),
      calculateDistance(72, bgW),
      calculateDistance(80, bgW),
      calculateDistance(87, bgW),
      calculateDistance(90, bgW),
      calculateDistance(96, bgW),
      calculateDistance(101.2, bgW),
    ],
    years: {
      2003: calculateDistance(sections.epvr - 3, bgW), //EPVR
      2006: calculateDistance(sections.peugeot, bgW), //peugeot
      2010: calculateDistance(sections.jellycode, bgW), //jellycode
      2014: calculateDistance(sections.oceanfone, bgW), //oceanfone
      2018: calculateDistance(sections.cvwarehouse, bgW), //cvwarehouse
      2019: calculateDistance(sections.marvil56, bgW), //M56
      2020: calculateDistance(sections.mindk, bgW), //MindK
    },
    clients: {
      peugeot: calculateDistance(sections.peugeot + 3, bgW),
      jellycode: calculateDistance(sections.jellycode + 3, bgW),
      oceanfone: calculateDistance(sections.oceanfone + 3, bgW),
      cvwarehouse: calculateDistance(sections.cvwarehouse + 3, bgW),
      marvil56: calculateDistance(sections.marvil56 + 3, bgW),
      mindk: calculateDistance(sections.mindk + 3, bgW),
    },
    drones: {
      peugeot: calculateDistance(sections.peugeot + 6.5, bgW),
      jellycode: calculateDistance(sections.jellycode + 6, bgW),
      oceanfone: calculateDistance(sections.oceanfone + 6.5, bgW),
      cvwarehouse: calculateDistance(sections.cvwarehouse + 6.5, bgW),
      marvil56: calculateDistance(sections.marvil56 + 6.5, bgW),
      mindk: calculateDistance(sections.mindk + 6.5, bgW),
    },
    me_speech: {
      start: {
        in: calculateDistance(0.5, bgW),
        out: calculateDistance(1.5, bgW),
      },
      go: {
        in: calculateDistance(2, bgW),
        out: calculateDistance(4, bgW),
      },
      cant: {
        in: calculateDistance(14, bgW),
        out: calculateDistance(16, bgW),
      },
      oceanfone: {
        in: calculateDistance(46, bgW),
        out: calculateDistance(47.5, bgW),
      },
    },
  };
};
