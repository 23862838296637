import React from "react";
import { modalActions, playerActions } from "../../../apollo/store";
import { useReactiveVar } from "@apollo/client";
import useSound from "use-sound";

import fx from "../../../assets/sounds/fx4.wav";
import fxOut from "../../../assets/sounds/fx5.wav";

import drone from "../../../assets/images/drone.gif";
import droneSkills from "../../../assets/images/drone_skills2.gif";
import droneRole from "../../../assets/images/drone_role.gif";
import droneProjects from "../../../assets/images/drone_projects.gif";

function Drone(props) {
  const { type, client, open } = useReactiveVar(modalActions);
  const { btnVolume, muted } = useReactiveVar(playerActions);
  let droneIcon = drone;

  switch (props.type) {
    case "skills":
      droneIcon = droneSkills;
      break;
    case "role":
      droneIcon = droneRole;
      break;
    case "projects":
      droneIcon = droneProjects;
      break;
  }

  const openModal = (client, type) => {
    document.body.classList.add("no-scroll");

    if (type != "role") {
      document.body.classList.add("no-scrollbar");
    } else {
      document.body.classList.remove("no-scrollbar");
    }
    modalActions({
      client: client,
      type: type,
      open: true,
    });
  };

  /* FX */
  const [playDrone] = useSound(fx, {
    volume: btnVolume,
  });
  const [playDroneOut] = useSound(fxOut, {
    volume: btnVolume,
  });

  return (
    <div
      onClick={() => {
        openModal(props.client, props.type);
        if (!muted) {
          playDroneOut();
        }
      }}
      onMouseEnter={() => {
        if (!muted) {
          playDrone();
        }
      }}
    >
      <img
        className={` ${props.className}`}
        alt=""
        src={droneIcon}
        id="drone"
      />
      <div className="label">{props.label}</div>
    </div>
  );
}

export default Drone;
