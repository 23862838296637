import React from "react";
import skill_bar from "../../../assets/images/skill_bar_blue.png";
import skill_bar_full from "../../../assets/images/skill_bar_full_blue.png";
import { skillSet } from "../../../helpers/skillIcons";
import Animate from "../../UI/Animate/Animate";

function Skill({ skill }) {
  let skillBars = [];
  for (let index = 0; index < 5; index++) {
    skillBars.push(
      <Animate
        id="skill-item-animation"
        className="skill-item-animation"
        offset="130"
        inAnimation="animate__bounceIn"
        outAnimation="fadeOutUp"
      >
        <img
          className="skill-bar animate__animated animate__bounceIn"
          alt=""
          src={index < skillSet[skill].count ? skill_bar_full : skill_bar}
          id="skill_bar"
        />
      </Animate>
    );
  }
  return (
    <Animate
      id="skill-animation"
      offset="120"
      inAnimation="animate__fadeInLeft"
      outAnimation="fadeOutUp"
      className="skill-wrapper"
    >
      <div className="skill-icon">
        <img alt="" src={skillSet[skill].icon} id="skill_bar" />
        <div>{skillSet[skill].name}</div>
      </div>
      <div className="skill-bars">{skillBars}</div>
    </Animate>
  );
}

export default Skill;
