import React from "react";

import Car from "./Car";

function Cars(props) {
  return (
    <div className="cars-wrapper" style={{ width: props.vw }}>
      <Car vw={props.vw} car={1} />
      <Car vw={props.vw} car={2} />
    </div>
  );
}

export default Cars;
