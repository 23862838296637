import React, { useState, useEffect, useRef } from "react";
import epvr from "../../../assets/images/EPVR.gif";
import Animate from "../../UI/Animate/Animate";
import { lang } from "../../../helpers/lang";

function Epvr(props) {
  return (
    <>
      <Animate
        id="epvr-speech-animation"
        offset="80"
        inAnimation="fadeInDown"
        outAnimation="fadeOutUp"
      >
        <div className="speech speech-one">{lang.epvr}</div>
      </Animate>

      <img id="epvr" className="stage-element wrapper" src={epvr} />
    </>
  );
}

export default Epvr;
