import React, { useState, useEffect, useRef } from "react";
//import useScrollDetector from "scroll-detect-hook";
import { useReactiveVar } from "@apollo/client";
import { set } from "immutable-modify";

//UTILS
import { appActions } from "./apollo/store";
import { vhTOpx } from "./helpers/utils";
import { useMediaQuery } from "react-responsive";

//UI
import Menu from "./components/UI/Menu/Menu";
import Loading from "./components/UI/Loading/Loading";
import Modals from "./components/UI/Modal/Modals";
import Label from "./components/UI/Label/Label";
import DesktopNotice from "./components/UI/DesktopNotice/DesktopNotice";

//STAGE
import Background from "./components/Stage/Background/Background";
import TitleScreen from "./components/Stage/TitleScreen/TitleScreen";
import Me from "./components/Stage/Me/Me";
import Ground from "./components/Stage/Ground/Ground";
import Clients from "./components/Stage/Clients/Clients";
import StageElement from "./components/Stage/StageElement/StageElement";
import Trees from "./components/Stage/Trees/Trees";
import Bushs from "./components/Stage/Bush/Bushs";
import AllDrones from "./components/Stage/Drones/AllDrones";
import Cars from "./components/Stage/Cars/Cars";
import CantWrapper from "./components/Stage/Cant/CantWrapper";
import Skills from "./components/Stage/Skills/Skills";
import Contacts from "./components/Stage/Contacts/Contacts";

function App() {
  const { bgW, loading, loadingTimeout } = useReactiveVar(appActions);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  /*eslint-disable */
  useEffect(() => {
    setTimeout(function () {
      let width = vhTOpx(2800);
      appActions(set(appActions(), "bgW", width - window.innerWidth));
      document.body.style.height = width;
      setTimeout(function () {
        appActions(set(appActions(), "loading", false));
        document.body.classList.remove("no-scroll");
      }, loadingTimeout);
    }, 700);
  }, []);
  useEffect(() => {
    console.log("APP LOADED");
  });
  /*eslint-enable */

  return (
    <div className="App" style={{ height: bgW }}>
      {loading && <Loading />}
      {isDesktopOrLaptop ? (
        <>
          <div
            className="stage"
            id="bg-wrapper"
            style={{ width: bgW, height: window.innerHeight }}
          >
            <TitleScreen />
            <Trees />
            <Bushs />

            <Label type="experience" />
            <Label type="skills" />
            <Label type="contacts" />

            <StageElement parallax="0.1">
              <Background type="1" />
            </StageElement>
            <StageElement parallax="0.5">
              <Background type="2" />
            </StageElement>
            <StageElement className="ground-stage" plusWidth="1000">
              <Ground />
            </StageElement>
            <Clients />

            <Skills />
          </div>

          <CantWrapper />

          <div
            className="clicable-section"
            id="clicable-section"
            style={{ height: bgW }}
          >
            {/* DRONES */}
            <AllDrones />
            {/* UI */}
            <Menu />

            <Modals actions={false} />
            <Me />
            <Cars vw={bgW} />

            <Contacts />
          </div>
        </>
      ) : (
        <DesktopNotice />
      )}
    </div>
  );
}

export default App;
