import skillIcon from "../assets/images/trophy.svg";
import skillPhp from "../assets/images/icons/php.png";
import skillJavascript from "../assets/images/icons/javascript.png";
import skillIllustrator from "../assets/images/icons/illustrator.png";
import skillPhotoshop from "../assets/images/icons/photoshop.png";
import skillMySql from "../assets/images/icons/mysql.png";
import skillWp from "../assets/images/icons/wordpress.png";
import skillReact from "../assets/images/icons/react.png";
import skillSass from "../assets/images/icons/sass.png";
import skillUbuntu from "../assets/images/icons/ubuntu.png";

export const skillSet = {
  illustrator: {
    icon: skillIllustrator,
    name: "Illustrator",
    count: 4,
  },
  photoshop: {
    icon: skillPhotoshop,
    name: "Photoshop",
    count: 4,
  },
  php: {
    icon: skillPhp,
    name: "PHP",
    count: 4,
  },
  mysql: {
    icon: skillMySql,
    name: "MySQL",
    count: 3,
  },
  javascript: {
    icon: skillJavascript,
    name: "Javascript",
    count: 3,
  },
  wordpress: {
    icon: skillWp,
    name: "wordpress",
    count: 4,
  },
  react: {
    icon: skillReact,
    name: "ReactJS",
    count: 4,
  },
  sass: {
    icon: skillSass,
    name: "Sass",
    count: 4,
  },
  ubuntu: {
    icon: skillUbuntu,
    name: "Ubuntu",
    count: 2,
  },
};
