import React from "react";
import { lang } from "../../../helpers/lang";
import avatar from "../../../assets/images/me-avatar.png";
var jsonQuery = require("json-query");

function RoleModal(props) {
  const client = jsonQuery(`clients[client=${props.client}]`, {
    data: lang,
  });

  console.log("22222");
  console.log(client);

  return (
    <div className="modal-wrapper-inside animate__animated animate__bounceInUp">
      <div className="modal role-modal-wrapper pixel">
        <div className="inside ">
          <div className="photo">
            <div className="avatar">
              <img src={avatar} />
            </div>
            <div className="role-desc">
              <div className="name">Bruno Santos</div>
              <label>Company</label>
              <div className="role">{client.value.name}</div>
              <label>Role</label>
              <div className="role">{client.value.roleTitle}</div>
            </div>
          </div>
          <div className="content">
            <label>Description</label>
            <div
              className="desc"
              dangerouslySetInnerHTML={{ __html: client.value.roleDescription }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoleModal;
