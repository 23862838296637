import React from "react";
import { useReactiveVar } from "@apollo/client";
import bush from "../../../assets/images/bush.png";
import { appActions } from "../../../apollo/store";

function Bush({ index }) {
  const { debug } = useReactiveVar(appActions);
  return (
    <>
      {debug && <span className="debug-label">{index}</span>}
      <img className="stage-element" alt="" src={bush} id="bush" />
    </>
  );
}

export default Bush;
