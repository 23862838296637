import React from "react";
import useScrollDetector from "scroll-detect-hook";
import titleImg from "../../../assets/images/title-screen.png";
import { useReactiveVar } from "@apollo/client";
import { appActions } from "../../../apollo/store";

var classNames = require("classnames");

function TitleScreen() {
  const [isScrolling, direction, speed, position] = useScrollDetector();
  const { loading } = useReactiveVar(appActions);

  let introClass = classNames({
    "title-screen-wrapper animate__animated": true,
    animate__fadeInDown: !loading,
  });

  if (isScrolling) {
    const distance = window.scrollY;

    document.querySelector(
      "#title-screen"
    ).style.transform = `translate(-${distance}px)`;

    let scrollRatio = 1 - distance / 700;
    document.querySelector("#title-screen").style.opacity = scrollRatio;
  }

  return (
    <div className={introClass}>
      <img alt="" src={titleImg} id="title-screen" />
    </div>
  );
}

export default TitleScreen;
