import React from "react";
import truck from "../../../assets/images/truck.png";
import { lang } from "../../../helpers/lang";

const Truck = () => {
  return (
    <div className="truck-wrapper">
      <img className="truck-img" alt="" src={truck} id="truck" />
    </div>
  );
};

export default Truck;
