import { makeVar } from "@apollo/client";

export const appActions = makeVar({
  bgW: 0,
  loading: true,
  loadingTimeout: 3000,
  debug: false,
});

export const cantActions = makeVar({
  cantOn: false,
});

export const modalActions = makeVar({
  client: null,
  type: null,
  open: false,
});

export const playerActions = makeVar({
  muted: true,
  backgroundIsPlaying: false,
  backgroundVolume: 20,
  cantIsPlaying: false,
  cantVolume: 70,
  btnVolume: 0.1,
  stepsVolume: 10,
});
