export const lang = {
  clients: [
    {
      client: "peugeot",
      name: "Peugeot",
      roleTitle: "Graphic Designer",
      roleDescription:
        "I started working at Peugeot as a graphic designer where I was responsible for creating brochures, flyers, cards, posters, newspaper advertisements etc, the tools at the time were Photoshop and Corel/Freehand. <br/><br/>After a few months, the opportunity arose to start creating websites, which involved creating web layouts, servers and databases - something I wasn't very comfortable with at the time, but with practice I managed to create several websites for multiple projects.",
      punchLine:
        "It all started at Peugeot, where I was a Graphic Designer. Here I started to take the first steps in web development.",
    },
    {
      client: "jellycode",
      name: "Jelly",
      roleTitle: "Full-Stack Developer",
      roleDescription:
        "At Jelly I was responsible for creating several websites for the company's clients, using PHP, MySql, Javascript/jQuery, CSS and HTML.<br/><br/>Since it is a branding/marketing company, we used Wordpress a lot because it was (and still is) a CMS that is easy for customers to use - all websites were created in Wordpress with custom templates, tailored to the customer's needs.",
      punchLine:
        "At Jelly Web Agency, I did +50 websites and started to improve my knowledge in PHP and MySQL.",
    },

    {
      client: "oceanfone",
      name: "Oceanfone",
      roleTitle: "Full-Stack Developer",
      roleDescription:
        "At Oceanfone it was where I performed the most diverse tasks in my career.<br/><br/>I was responsible for creating all the company's graphic material, such as logos, business cards, brochures, flyers and rollups. I also created materials for social networks and web advertisements.<br/><br/>With mobile communications being the focus of the company, using technologies like Voip and WebRTC - I learned a lot about these technologies and the concepts between them. I also learned more about servers, APIs, databases, among others and started to develop hybrid mobile apps using Adobe Phonegap.",
      punchLine:
        "At Oceanfone, I learned more about servers and VOIP technology. Also started building hybrid mobile apps.",
    },

    {
      client: "scorepixel",
      name: "Scorepixel",
      roleTitle: "Full-Stack Developer",
      roleDescription: "...",
      punchLine:
        "I created Scorepixel as a business for my personal projects, where I take care of all UI/UX, coding, research etc from all of them.",
    },

    {
      client: "cvwarehouse",
      name: "CVwarehouse",
      roleTitle: "Frontend Developer",
      roleDescription:
        "My role at CVwarehouse was to modify the UI/UX of the company's main app (ATS) so that it was easier to use and had a more modern interface.",
      punchLine:
        "At CVwarehouse, I was responsible for the UI/UX of their main product.",
    },

    {
      client: "marvil56",
      name: "Marvil56",
      roleTitle: "Full-Stack Developer",
      roleDescription:
        "At Marvil56 I found a very ambitious project - connecting several online stores to a digital warehouse through a custom API and a web app created in React.<br/><br/>It was at this point that I took the opportunity to deepen my knowledge in React - and I loved it. It was very challenging because I was the only person responsible for the project and I did it from start to finish.<br/><br/>Along with that, I created several websites for the company's products.",
      punchLine:
        "At Marvil56, I developed websites for their products and started creating React apps.",
    },

    {
      client: "mindk",
      name: "MindK",
      roleTitle: "Full-Stack Developer",
      roleDescription:
        "MindK is the first company where I started working 100% remotely.<br/><br/>My main responsibilities are creating several websites using WordPress as a CMS, creating custom themes and plugins, managing servers and developing some headless websites using Gatsby/React/Graphql.<br/><br/>I started to make some scripts in bash to facilitate website migrations between servers and other features that are necessary to speed up and optimize the productivity of my work.",
      punchLine:
        "At MindK, I'm creating all kind of websites using Wordpress/React and I'm fully remote.",
    },
  ],
  cant: "You are just a designer, you will never be a developer!",
  epvr: "I finished my graphic design course at EPVR, Lisbon - Portugal",
  drones: {
    skills: "Skills",
    projects: "Projects",
    role: "Role",
  },
  gameboy: "I love retro gamming stuff :)",
  tv: "I like to watch all kind of movies",
  truck: "Lets talk!",
  sayhi: {
    title: "Get in touch!",
    text: "Whether you have a question or just want to say hi, I’ll try my best to get back to you!",
  },
};
