import React from "react";
import { useReactiveVar } from "@apollo/client";
import tree from "../../../assets/images/tree.png";
import { appActions } from "../../../apollo/store";

const Tree = ({ index }) => {
  const { debug } = useReactiveVar(appActions);
  return (
    <>
      {debug && <span className="debug-label">{index}</span>}
      <img className="stage-element" alt="" src={tree} id="tree" />
    </>
  );
};

export default Tree;
