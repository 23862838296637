import React from "react";
import Player from "./Player";
import Lives from "./Lives";

function Menu() {
  return (
    <div className="menu-wrapper">
      <Lives />
      <Player />
    </div>
  );
}

export default Menu;
