import React from "react";
import StageElement from "../../Stage/StageElement/StageElement";
import { cantActions } from "../../../apollo/store";
import { useReactiveVar } from "@apollo/client";
import { Distances } from "../../../helpers/distances";
import Cant from "./Cant";

var classNames = require("classnames");

function CantWrapper() {
  const { cantOn } = useReactiveVar(cantActions);
  var cantClass = classNames({
    "cant-bg": true,
    on: cantOn,
  });
  return (
    <div className="cant-wrapper">
      <div className={cantClass}></div>
      <StageElement className="cant-stage" left={Distances().sections.cant}>
        <Cant />
      </StageElement>
    </div>
  );
}

export default CantWrapper;
