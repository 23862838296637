import React, { useState, useEffect, useRef } from "react";
import { lang } from "../../../helpers/lang";
import jellycodeImg from "../../../assets/images/clients/jellycode.png";
import peugeotImg from "../../../assets/images/clients/peugeot.png";
import oceanfoneImg from "../../../assets/images/clients/oceanfone.png";
import cvwarehousetImg from "../../../assets/images/clients/cvwarehouse.png";
import marvilImg from "../../../assets/images/clients/m56.png";
import mindkImg from "../../../assets/images/clients/mindk.png";
import Animate from "../../UI/Animate/Animate";

var classNames = require("classnames");

function ClientWrapper({ client }) {
  let clientImage = {
    jellycode: jellycodeImg,
    peugeot: peugeotImg,
    oceanfone: oceanfoneImg,
    scorepixel: peugeotImg,
    cvwarehouse: cvwarehousetImg,
    marvil56: marvilImg,
    mindk: mindkImg,
  };

  const punchLine = lang.clients.filter((cl) => {
    return cl.client === client;
  });

  let speechClass = classNames({
    speech: true,
    "speech-one": true,
  });

  return (
    <>
      <Animate
        id={`${client}-speech-animation`}
        className="speech-animation-wrapper"
        offset="80"
        inAnimation="fadeInDown"
        outAnimation="fadeOutUp"
      >
        <div className={speechClass}>{punchLine[0].punchLine}</div>
      </Animate>
      <img
        className="stage-element wrapper"
        alt=""
        src={clientImage[client]}
        id={client}
      />
    </>
  );
}

export default ClientWrapper;
