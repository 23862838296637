import React from "react";

import desktop from "../../../assets/images/icons/desktop.png";

function DesktopNotice() {
  return (
    <div className="desktop-notice-wrapper">
      <div className="image">
        <img src={desktop} id="desktop" />
      </div>
      <div className="title">Access on a desktop</div>
      <div className="text">I am working on a better mobile experience.</div>
    </div>
  );
}

export default DesktopNotice;
